import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KEY_ACCESS_TOKEN, KEY_USER_TYPE } from 'app-constants';
import moment from 'moment';
import numeral from 'numeral';
import { KEY_CUSTOMERS_Dashboard_FILTER, KEY_CUSTOMERS_FILTER, KEY_Dashboard_FILTER as KEY_DASHBOARD_FILTER, KEY_DEPOSITS_FILTER, KEY_INVOICES_FILTER, KEY_NAME, KEY_ORDERS_FILTER, KEY_PRODUCTS_Dashboard_FILTER, KEY_ROLE, KEY_SALESMEN_Dashboard_FILTER, KEY_USER_INFO } from 'app-constants/index2';
const { REACT_APP_LIGHT_LOGO: LIGHT_LOGO, REACT_APP_DARK_LOGO: DARK_LOGO } = process.env;

toast.configure();

export function successNotify(message) {
  toast.success(message,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 3000,
      rtl: false,
    }
  );
}

export function errorNotify(message) {
  toast.error(message,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      autoClose: 5000,
      rtl: false,
    }
  );
}

export function emptyCache(isToLogin = false) {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  if (!isToLogin) {
    setTimeout(() => window.location.reload());
  } else {
    setTimeout(() => window.location.assign("/login"));
  }
}

export function removeAfterLogout() {
  localStorage.removeItem(KEY_USER_TYPE);
  localStorage.removeItem(KEY_ACCESS_TOKEN);
  localStorage.removeItem(KEY_USER_INFO);
}

export function logoutAndRedirect() {
  removeAfterLogout();
  emptyCache();
}


export function getAccessToken() {
  let access_token = undefined;
  if (localStorage.getItem(KEY_ACCESS_TOKEN) !== undefined && localStorage.getItem(KEY_ACCESS_TOKEN) !== null) {
    access_token = 'Bearer ' + localStorage.getItem(KEY_ACCESS_TOKEN);
  }
  return access_token;
}

export function getName() {
  let name = "";
  if (localStorage.getItem(KEY_NAME) !== undefined && localStorage.getItem(KEY_NAME) !== null) {
    name = localStorage.getItem(KEY_NAME);
  }
  return name;
}


export function getRole() {
  let role = "";
  if (localStorage.getItem(KEY_ROLE) !== undefined && localStorage.getItem(KEY_ROLE) !== null) {
    role = localStorage.getItem(KEY_ROLE);
  }
  return role;
}


export function isLogin() {
  if (getAccessToken() !== null && getAccessToken() !== undefined) {
    return true;
  }
  return false;
}

export function dateInputFormmater(date) {
  if (date !== undefined && date !== null && date !== '')
    return moment(date).format('DD/MM/yyyy');
  else
    return null;
}

export function dateFormmater(date) {
  if (date !== undefined && date !== null && date !== '')
    return moment(date).utc().format('DD/MM/yyyy hh:mm A');
  else
    return null;
}

export function dateFormmaterWithoutTime(date) {
  if (date !== undefined && date !== null && date !== '')
    return moment(date).utc().format('DD/MM/yyyy');
  else
    return null;
}

export function dateFormmaterToAPI(date) {
  if (date !== undefined && date !== null && date !== '')
    return moment(date).add(1, 'd').utc().format("yyyy-MM-DD[T]HH:mm:ss");
  else
    return null;
}

export function getTimeFromUnix(date) {
  return moment.unix(date).format('LT');
}

export function getDateFromUnix(date) {
  return moment.unix(date).format('L');
}

export function numberWithCommas(number, currency = null) {
  let formatLocal = currency === null
    ? "0,0"
    : currency === "LBP"
      ? "0,0"
      : "0,0.00"
  return number && numeral(parseFloat(number).toFixed(2)).format(formatLocal)
}

export function getArrayToSSelect(array) {
  return array?.map((item) => {
    return { value: item?.id, name: item?.name }
  })
}


export function getLatLngFromUrl(url) {
  //var url = "https://www.google.com/maps/place/Arctic+Pixel+Digital+Solutions/@63.6741553,-164.9587713,4z/data=!3m1!4b1!4m5!3m4!1s0x5133b2ed09c706b9:0x66deacb5f48c5d57!8m2!3d64.751111!4d-147.3494442";
  const regex = new RegExp('@(.*),(.*),');
  const lat_long_match = url.match(regex);
  const lat = lat_long_match[1];
  const long = lat_long_match[2];
  return { lat: lat, lng: long }
}

/* Start - Orders Page Filters */
export function saveOrdersFilter(params) {
  localStorage.setItem(KEY_ORDERS_FILTER, JSON.stringify(params))
}

export function getOrdersFilter() {
  let ordersFilter = null
  if (localStorage.getItem(KEY_ORDERS_FILTER)) {
    ordersFilter = JSON.parse(localStorage.getItem(KEY_ORDERS_FILTER))
  }
  return ordersFilter
}

export function resetOrdersFilter() {
  localStorage.removeItem(KEY_ORDERS_FILTER)
}

/* End - Orders Page Filters */

/* Start - Dashboard Page Filters */

/* DASHBOARD - ORDER FILTERS */
export function saveOrdersDashboardFilter(params) {
  localStorage.setItem(KEY_DASHBOARD_FILTER, JSON.stringify(params))
}

export function getOrdersDashboardFilter() {
  let dashboardFilter = null
  if (localStorage.getItem(KEY_DASHBOARD_FILTER)) {
    dashboardFilter = JSON.parse(localStorage.getItem(KEY_DASHBOARD_FILTER))
  }
  return dashboardFilter
}

export function resetOrdersDashboardFilter() {
  localStorage.removeItem(KEY_DASHBOARD_FILTER)
}



/* DASHBOARD -  CUSTOMERS FILTERS */
export function saveCustomersDashboardFilter(params) {
  localStorage.setItem(KEY_CUSTOMERS_Dashboard_FILTER, JSON.stringify(params))
}

export function getCustomersDashboardFilter() {
  let dashboardFilter = null
  if (localStorage.getItem(KEY_CUSTOMERS_Dashboard_FILTER)) {
    dashboardFilter = JSON.parse(localStorage.getItem(KEY_CUSTOMERS_Dashboard_FILTER))
  }
  return dashboardFilter
}

export function resetCustomersDashboardFilter() {
  localStorage.removeItem(KEY_CUSTOMERS_Dashboard_FILTER)
}



/* DASHBOARD -  PRODUCTS FILTERS */
export function saveProductsDashboardFilter(params) {
  localStorage.setItem(KEY_PRODUCTS_Dashboard_FILTER, JSON.stringify(params))
}

export function getProductsDashboardFilter() {
  let dashboardFilter = null
  if (localStorage.getItem(KEY_PRODUCTS_Dashboard_FILTER)) {
    dashboardFilter = JSON.parse(localStorage.getItem(KEY_PRODUCTS_Dashboard_FILTER))
  }
  return dashboardFilter
}

export function resetProductsDashboardFilter() {
  localStorage.removeItem(KEY_PRODUCTS_Dashboard_FILTER)
}


/* DASHBOARD - SALESMEN */
export function saveSalesmenFilter(params) {
  localStorage.setItem(KEY_SALESMEN_Dashboard_FILTER, JSON.stringify(params))
}

export function getSalesmenFilter() {
  let ordersFilter = null
  if (localStorage.getItem(KEY_SALESMEN_Dashboard_FILTER)) {
    ordersFilter = JSON.parse(localStorage.getItem(KEY_SALESMEN_Dashboard_FILTER))
  }
  return ordersFilter
}

export function resetSalesmenFilter() {
  localStorage.removeItem(KEY_SALESMEN_Dashboard_FILTER)
}

/* End - Dashboard Page Filters */


/* Start - Customers Page Filters */
export function saveCustomersFilter(params) {
  localStorage.setItem(KEY_CUSTOMERS_FILTER, JSON.stringify(params))
}

export function getCustomersFilter() {
  let customersFilter = null
  if (localStorage.getItem(KEY_CUSTOMERS_FILTER)) {
    customersFilter = JSON.parse(localStorage.getItem(KEY_CUSTOMERS_FILTER))
  }
  return customersFilter
}

export function resetCustomersFilter() {
  localStorage.removeItem(KEY_CUSTOMERS_FILTER)
}

/* End - Customers Page Filters */

/* Start - Deposits Page Filters */
export function saveDepositsFilter(params) {
  localStorage.setItem(KEY_DEPOSITS_FILTER, JSON.stringify(params))
}

export function getDepositsFilter() {
  let depositsFilter = null
  if (localStorage.getItem(KEY_DEPOSITS_FILTER)) {
    depositsFilter = JSON.parse(localStorage.getItem(KEY_DEPOSITS_FILTER))
  }
  return depositsFilter
}

export function resetDepositsFilter() {
  localStorage.removeItem(KEY_DEPOSITS_FILTER)
}

/* End - Deposits Page Filters */

/* Start - User Info */

export function saveUserInfo(params) {
  localStorage.setItem(KEY_USER_INFO, JSON.stringify(params))
}

export function getUserInfo() {
  let userInfo = null
  if (localStorage.getItem(KEY_USER_INFO)) {
    userInfo = JSON.parse(localStorage.getItem(KEY_USER_INFO))
  }
  return userInfo
}

export function resetUserInfo() {
  localStorage.removeItem(KEY_USER_INFO)
}


/* End - User Info */

export function saveInvoicesFilter(params) {
  localStorage.setItem(KEY_INVOICES_FILTER, JSON.stringify(params))
}

export function getInvoicesFilter() {
  let invoicesFilter = null
  if (localStorage.getItem(KEY_INVOICES_FILTER)) {
    invoicesFilter = JSON.parse(localStorage.getItem(KEY_INVOICES_FILTER))
  }
  return invoicesFilter
}

export function resetInvoicesFilter() {
  localStorage.removeItem(KEY_INVOICES_FILTER)
}

export function paginate(arr, size) {
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}


export function getLightLogo() {
  try {
    return require(`assets/images/logo/${LIGHT_LOGO}`).default;
  } catch (err) {
    return null;
  }
}

export function getDarkLogo() {
  try {
    return require(`assets/images/logo/${DARK_LOGO}`).default;
  } catch (err) {
    return null;
  }
}

export function setGlobalProperty(property, newColor) {
  document.documentElement.style.setProperty(property, newColor);
}

export const currencyFormatter = (value, currency = "USD") => {
  return numberWithCommas(value, currency);
}