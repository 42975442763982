export const KEY_NAME = "KEY_NAME";
export const KEY_ROLE = "KEY_ROLE";
export const KEY_ORDERS_FILTER = "KEY_ORDERS_FILTER";
export const KEY_INVOICES_FILTER = "KEY_INVOICES_FILTER";
export const KEY_Dashboard_FILTER = "KEY_Dashboard_FILTER";
export const KEY_CUSTOMERS_Dashboard_FILTER = "KEY_CUSTOMERS_Dashboard_FILTER";
export const KEY_PRODUCTS_Dashboard_FILTER = "KEY_PRODUCTS_Dashboard_FILTER";
export const KEY_SALESMEN_Dashboard_FILTER = "KEY_SALESMEN_Dashboard_FILTER";
export const KEY_USER_INFO = "KEY_USER_INFO";
export const KEY_CUSTOMERS_FILTER = "KEY_CUSTOMERS_FILTER";
export const KEY_DEPOSITS_FILTER = "KEY_DEPOSITS_FILTER";

export const PAGINATION_SIZE = 60;
