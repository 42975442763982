import React, { useEffect } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { isLogin, setGlobalProperty } from './services'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { lazyWithRetry } from "services/lazyWithRetry";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = lazyWithRetry(() => import('./layout/DefaultLayout'))

// Pages
const Login = lazyWithRetry(() => import('./pages/login'))
const Register = lazyWithRetry(() => import('./pages/register/Register'))
const Page404 = lazyWithRetry(() => import('./pages/page404/Page404'))
const Page500 = lazyWithRetry(() => import('./pages/page500/Page500'))

const App = () => {
  const { REACT_APP_PROJECT_TYPE: PROJECT_TYPE } = process.env;

  useEffect(() => {
    if (PROJECT_TYPE === "ASTORE") {
      setGlobalProperty("--cui-sidebar-brand-bg", "#191919");
      setGlobalProperty("--cui-sidebar-toggler-bg", "#191919");
      setGlobalProperty("--cui-sidebar-nav-link-bg", "#323232");
      setGlobalProperty("--cui-dark", "#4c4c4c");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route path="/" name="Home" render={
            (props) =>
              isLogin()
                ? <DefaultLayout {...props} />
                : <Redirect to={"/login"} />
          } />
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default App
