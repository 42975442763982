
/* export const BASE_URL = "https://admin.abrandsint.com/api/v1/" */
/* export const BASE_URL = "https://dev.abrandsint.com/api/v1/" */
/* export const BASE_URL = "http://192.168.1.4:3000/api/v1/" */


const { REACT_APP_BASE_API_URL: BASE_URL_LOCAL } = process.env;
export const BASE_URL = BASE_URL_LOCAL;

//KEY VALUE LOCAL STORAGE
export const KEY_USER_TYPE = "KEY_USER_TYPE";
export const KEY_ACCESS_TOKEN = "KEY_ACCESS_TOKEN";

export const GOOGLE_MAPS_KEY = "AIzaSyC7LVVP-Uw2_PE4Cm8uuz8AtfDTq_p3to0"
